export const DCAAS_QUERY = `
  query {
    dcaas {
      id
      property {
        id
        name
      }
      initialAuditCompletionDate
      auditorName
      quarterlyAuditCompletionDate
      address
      website
      directPhoneNumber
      email
      propertyDescription
      unitCount
      totalBuildings
      numberOfStories
      applicationFee
      adminFee
      storageFee
      rentSpecials
      offerDuration
      approved
    }
  }
`

export const DCAA_QUERY = `
  query($dcaaId: ID!) {
    dcaa(dcaaId: $dcaaId){
      id
      property {
        id
        name
      }
      initialAuditCompletionDate
      auditorName
      quarterlyAuditCompletionDate
      address
      website
      directPhoneNumber
      email
      propertyDescription
      unitCount
      totalBuildings
      numberOfStories
      applicationFee
      adminFee
      storageFee
      rentSpecials
      offerDuration
      leaseTerms
      utilitiesIncluded
      includedUtilities
      officeHours
      feedInformation
      parking
      petPolicy
      floorPlans
      photos
      videos
      matterports
      propertyMedias
      custom
      ilsMedia
      features
      services
      interior
      fitnessAndRecreation
      outdoorSpaces
      kitchen
      studentFeatures
      living
      otherFeatures
      standOut
      nearbyFacilities
      fieldStructures
      approved
      dcaaComments {
        id
        content
        sectionName
        user {
          id
          name
        }
      }
    }
  }
`

export const CREATE_OR_UPDATE_DCAA_COMMENT_MUTATION = `
  mutation CreateOrUpdateDcaaCommentMutation($dcaaId: ID!, $content: String!, $sectionName: String!) {
    createOrUpdateDcaaCommentMutation(dcaaId: $dcaaId, content: $content, sectionName: $sectionName) {
      id
      content
      sectionName
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_APPROVED_STATUS_MUTATION = `
  mutation UpdateApprovedStatusMutation($id: ID!, $approved: Boolean!) {
    updateApprovedStatusMutation(id: $id, approved: $approved) {
      approved
      quarterlyAuditCompletionDate
    }
  }
`
