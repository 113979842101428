import { Table, Dropdown, Modal, Button, Textarea } from "flowbite-react"
import _ from "lodash"
import React, { useState, useEffect } from "react"
import { useQuery, useMutation } from "urql"

import { DCAA_QUERY, CREATE_OR_UPDATE_DCAA_COMMENT_MUTATION, UPDATE_APPROVED_STATUS_MUTATION } from "../../queries/DcaasMutations"
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline"

export default function DCAA() {
  const pathname = window.location.pathname
  const id = pathname.split("/").pop()

  const [selectedFloorPlanSource, setSelectedFloorPlanSource] = useState("property_website")
  const [commentData, setCommentData] = useState({}) // Store comments for each section
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeSection, setActiveSection] = useState(null)
  const [currentComment, setCurrentComment] = useState("")
  const [approved, setApproved] = useState(false)


  const [result, executeMutation] = useMutation(CREATE_OR_UPDATE_DCAA_COMMENT_MUTATION)
  const [approveResult, executeApprovedMutation] = useMutation(UPDATE_APPROVED_STATUS_MUTATION)


  const [{ data: dcaasData }] = useQuery({
    query: DCAA_QUERY,
    variables: { dcaaId: id }
  })

  const dcaa = dcaasData?.dcaa
  const fieldStructures = dcaa?.fieldStructures

  useEffect(() => {
    if (dcaa?.approved !== undefined) {
      setApproved(dcaa.approved)
    }

    if (dcaa?.dcaaComments) {
      const initialComments = dcaa.dcaaComments.reduce((acc, comment) => {
        acc[comment.sectionName] = comment.content
        return acc
      }, {})
      setCommentData(initialComments)
    }
  }, [dcaa])

  const handleApprove = async () => {
    const variables = { id, approved: true }
    try {
      const { data, error } = await executeApprovedMutation(variables)
      if (error) {
        console.error("Approval mutation error:", error)
        setApproved(false)
      } else {
        console.log("Approval updated:", data.updateApprovedStatusMutation)
        dcaa.approved = data.updateApprovedStatusMutation.approved
        dcaa.quarterlyAuditCompletionDate = data.updateApprovedStatusMutation.quarterlyAuditCompletionDate
        setApproved(true)
      }
    } catch (err) {
      console.error("Unexpected error:", err)
    }
  }

  const handleCommentButtonClick = (section) => {
    setActiveSection(section)
    setCurrentComment(commentData[section] || "") // Load the existing comment if present
    setIsModalOpen(true)
  }

  const handleCommentSubmit = async (e) => {
    e.preventDefault()
    // Update the local comment data for the specific section
    setCommentData((prev) => ({
      ...prev,
      [activeSection]: currentComment
    }))

    // Define the variables for the mutation
    const variables = {
      dcaaId: id, // Use the DCAA id from the URL
      content: currentComment, // Pass the current comment as content
      sectionName: activeSection // Pass the active section name
    }

    try {
      const { data, error } = await executeMutation(variables)

      if (error) {
        console.error("Mutation error:", error)
      } else {
        console.log("Comment created:", data.createOrUpdateDcaaCommentMutation)
        // Close the modal and reset the active section and comment after successful submission
        setIsModalOpen(false)
        setActiveSection(null)
        setCurrentComment("")
      }
    } catch (err) {
      console.error("Unexpected error:", err)
    }
  }

  const renderArray = (arr) => (
    <div>
      {arr.map((item, index) => (
        <div key={index} className="flex w-full flex-col border-b border-bgray">
          <div className="px-3">{item}</div>
        </div>
      ))}
    </div>
  )

  const fields = [
    "initialAuditCompletionDate",
    "auditorName",
    "quarterlyAuditCompletionDate",
    "address",
    "website",
    "directPhoneNumber",
    "email",
    "propertyDescription",
    "unitCount",
    "totalBuildings",
    "numberOfStories",
    "applicationFee",
    "adminFee",
    "storageFee",
    "rentSpecials",
    "offerDuration"
  ]

  const renderFloorPlans = () => (
    <div className="flex w-full flex-col">
      <div className="font-bold">Floor Plans:</div>

      {dcaa?.floorPlans && (
        <div className="my-3 overflow-x-auto">
          <Dropdown label={dcaa?.floorPlans[selectedFloorPlanSource]?.source_name || "Source"} inline>
            {dcaa?.floorPlans &&
              Object.keys(dcaa?.floorPlans).map((sourceKey) => {
                const source = dcaa.floorPlans[sourceKey]
                return (
                  <Dropdown.Item
                    key={sourceKey}
                    value={sourceKey}
                    onClick={() => setSelectedFloorPlanSource(sourceKey)}
                  >
                    {source["source_name"]}
                  </Dropdown.Item>
                )
              })}
          </Dropdown>
          <Table>
            <Table.Head>
              <Table.HeadCell>Floorplan name</Table.HeadCell>
              <Table.HeadCell>Beds</Table.HeadCell>
              <Table.HeadCell>Baths</Table.HeadCell>
              <Table.HeadCell>Sq feet</Table.HeadCell>
              <Table.HeadCell>Price</Table.HeadCell>
              <Table.HeadCell>Units</Table.HeadCell>
              <Table.HeadCell>Units Available</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {dcaa?.floorPlans &&
                dcaa?.floorPlans[selectedFloorPlanSource]?.floor_plans?.map((floorplan, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{floorplan["floorplan_name"]}</Table.Cell>
                    <Table.Cell>{floorplan["beds"]}</Table.Cell>
                    <Table.Cell>{floorplan["baths"]}</Table.Cell>
                    <Table.Cell>{floorplan["sq_feet"]}</Table.Cell>
                    <Table.Cell>{floorplan["price"]}</Table.Cell>
                    <Table.Cell>{floorplan["units"]}</Table.Cell>
                    <Table.Cell>{floorplan["units_available"]}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  )

  const fieldStructureAttributes = (fieldName) => {
    const fieldStructure = fieldStructures?.[_.snakeCase(fieldName)]
    let keys = fieldStructure ? Object.keys(fieldStructure) : []
    keys = keys.filter((key) => key !== "keys")
    return keys
  }

  const renderObject = (fieldName, title) => {
    const fieldStructure = fieldStructures?.[_.snakeCase(fieldName)]

    return (
      <div className="flex w-full flex-col">
        <div className="font-bold mb-1 mt-2 flex justify-between">
          {title || _.startCase(fieldName)}
          {!approved && (<a
            onClick={(e) => {
              e.preventDefault();
              handleCommentButtonClick(fieldName);
            }}
            className="mx-4 mr-4 cursor-pointer text-grey"
            href="#"
          >
            {commentData[fieldName] ? (
              <span className="underline">Edit comment</span>
            ) : (
              <ChatBubbleLeftIcon width={18} height={18} />
            )}
          </a>)}
        </div>
        <div className="pl-3">
          {fieldStructureAttributes(fieldName).map((key) => (
            <div key={key} className="flex w-full flex-row border-b border-lightgray mb-1 pb-1">
              <div className="basis-1/3 font-medium">{_.startCase(key)}:</div>
              <div className="pl-3">
                {fieldStructure?.[key]?.type === "boolean"
                  ? dcaa?.[fieldName]?.[key] === "1" || dcaa?.[fieldName]?.[key] === true
                    ? "Yes"
                    : ""
                  : dcaa?.[fieldName]?.[key]}
              </div>
              {fieldStructure?.[key]?.with_description && (
                <div className="px-3">{dcaa?.[fieldName]?.[`${key}_description`]}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderField = (value, fieldName) => (
    <div key={fieldName} className="flex w-full flex-row border-b border-lightgray mb-1 pb-1">
      <div className="basis-1/4 font-bold">{_.startCase(fieldName)}:</div>
      <div className="pl-3 flex-grow">{value ? value : ""}</div>
      {!approved && (<a
        onClick={(e) => {
          e.preventDefault();
          handleCommentButtonClick(fieldName);
        }}
        className="mx-4 cursor-pointer text-grey"
        href="#"
      >
        {commentData[fieldName] ? (
          <span className="underline">Edit comment</span>
        ) : (
          <ChatBubbleLeftIcon width={18} height={18} />
        )}
      </a>)}
    </div>
  )

  const renderFields = () => fields.map((field) => renderField(dcaa?.[field], field))

  const renderArrayField = (fieldName) =>
    dcaa?.[fieldName] && (
      <div className="m-3 flex w-full flex-col">
        <div className="bg-bgray font-bold flex justify-between">
          {_.startCase(fieldName)}
          {!approved && (<a
            onClick={(e) => {
              e.preventDefault();
              handleCommentButtonClick(fieldName);
            }}
            className="mx-4 cursor-pointer text-grey"
            href="#"
          >
            {commentData[fieldName] ? (
              <span className="underline">Edit comment</span>
            ) : (
              <ChatBubbleLeftIcon width={18} height={18}/>
            )}
          </a>)}
        </div>
        <div className="pl-3">{dcaa?.[fieldName] && renderArray(dcaa?.[fieldName])}</div>
      </div>
    )

  const renderSubTitle = (title) => <h1 className="text-xl font-semibold mb-2">{title}</h1>;

  return (
    <div className="p-8">
      <div className="mb-4 flex justify-between">
        {renderSubTitle("Onboarding Checklist for Internet Listing Services (inclusive of GMB)")}
        <Button
          onClick={handleApprove}
          disabled={approved}
          color={approved ? "gray" : "blue"}
        >{approved ? "Approved" : "Approve"}</Button>
      </div>
      {dcaa?.property && renderField(dcaa?.property?.name, "property")}
      {renderFields()}
      <div className="columns-2 gap-2 mt-6">
        {renderObject("leaseTerms", "Lease Terms")}
        {renderObject("officeHours", "Office Hours")}
        {/* {renderObject("utilitiesIncluded", "Utilities Included")} */}
        {renderObject("includedUtilities", "Included Utilities")}
        {renderObject("feedInformation", "Feed Information")}
      </div>
      <div className="mt-6">{renderSubTitle("Amenities")}</div>
      <div className="flex columns-2 gap-2">
        {renderObject("parking", "Parking")}
        {renderObject("petPolicy", "Pet Policy")}
      </div>
      {renderFloorPlans()}
      {renderArrayField("photos")}
      {renderArrayField("videos")}
      {renderArrayField("matterports")}
      {renderArrayField("propertyMedias")}
      {renderArrayField("custom")}
      <div className="columns-2 gap-2">
        {renderObject("ilsMedia", "ILS Media")}
        {renderObject("features", "Features")}
        {renderObject("services", "Services")}
        {renderObject("interior", "Interior")}
        {renderObject("livingSpace", "Living Space")}
      </div>
      {/* Comment Modal */}
      <Modal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="modal-dialog"
      >
        <Modal.Header>Comment for: {_.startCase(activeSection)}</Modal.Header>
        <Modal.Body>
          <Textarea
            value={currentComment}
            onChange={(e) => setCurrentComment(e.target.value)}
            placeholder="Add your comment here"
            rows={4}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCommentSubmit}>Save</Button>
          <Button color="gray" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
